<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="writePost">
  <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <div>
      <table style="width:100%;"><tr><td style="text-align: center; width:90%; background-color: rgb(180,180,180);">제목</td>
        <td style="background-color: rgb(180,180,180); text-align: center;">작성자</td>
      </tr>
      <tr>
        <td style="display: flex;">
          <v-select v-model="writePostVar.postType" label="text"
            :options="[{value: 0, text:'일반'},{value: 1, text:'공지'}]" style="width: 120px;" />
          <input placeholder="제목을 입력하세요" style="margin-left: 5px; width:100%;" v-model="writePostVar.title"/>
        </td>
        <td style="text-align: center;">{{writePostVar.writerName}}</td>
      </tr>
      <tr><td colspan="2">
        <div id="app">
          <ckeditor :editor="editor" v-model="writePostVar.contents" :config="editorConfig"></ckeditor>
        </div>
        </td>
      </tr><tr :data="tr" :key="index" v-for="(tr, index) in writePostVar.fileNames.filter(e => {let flg = true; for(var file in files){ if(e.includes(files[file].name)) flg = false;}; return flg; })"><td colspan="2">
          <button @click="removeFile(index)">{{tr}} : 삭제</button></td></tr>
      <tr :data="tr" :key="index" v-for="(tr, index) in files"><td colspan="2">
          <button @click="removeFile(index)">{{tr.name}} : 삭제</button>
          <img class="preview" v-bind:ref="'preview'+parseInt( index )"/>
        </td>
      </tr>
      <tr><td colspan="2">
        <!-- <vs-upload automatic :fileName="writePostVar.fileName" :action="makeUploadLink()" @on-success="successUpload" @on-error="failUpload" @on-delete="deleteFile" /> -->
  <div id="file-drag-drop">
    <form class="drag-drop" ref="fileform">
      <label class="drop-files">여기를 클릭하거나 파일을 드래그해서 넣어주세요
      <input multiple type="file" @change="addFile"></label>
      <progress max="100" :value.prop="uploadPercentage"></progress>
    </form>
  </div>
        </td></tr>
      </table>
      <vs-button style="float: right; margin-right: 50px;" id='sendButton' color="primary" type="filled" v-on:click="goToList()" >목록</vs-button>
      <vs-button :disabled="!writePostVar.title" style="float: right; margin-right: 30px;" id='sendButton' color="primary" type="filled" v-on:click="setPost()" >저장</vs-button>
    </div>
  </div>
    <!-- End: 뉴스 상세보기 팝업 -->
</template>

<script>
// import classicEditor from '@ckeditor/ckeditor5-build-classic';
// import decouplededitor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Table from '@ckeditor/ckeditor5-table/src/table';
import List from '@ckeditor/ckeditor5-list/src/list';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import axios from 'axios'
export default {
  components: {
  },
  data() {
    return {
      currentx: 1,
      numOfPosts: 0,
      numOfPages: 0,
      writePostVar:{
        postType: {value: 0, text: '일반'},
        tbcode: '',
        idx: '',
        title: '',
        contents: '',
        writerName: '',
        writerIdx: '',
        fileName: 'newFiles',
        fileNames: [],
      },
      files: [],
      dragAndDropCapable: true,
      uploadPercentage: 0,
      // editor: classicEditor,
      editor: ClassicEditor,
      // editor: decouplededitor,
      // editorData: '',
      editorConfig: {
        plugins: [
            EssentialsPlugin,
            BoldPlugin,
            ItalicPlugin,
            LinkPlugin,
            ParagraphPlugin,
            Font,
            Table,
            List,
            PasteFromOffice,
        ],
        toolbar: {
            items: ["undo", "redo", "heading", "bold", "italic", "fontColor", "link", "numberedList", "bulletedList", "|", "insertTable", "tableColumn", "tableRow", "mergeTableCells"]
            // ['bold', 'italic', 'link', 'undo',  'redo', "|", "imageUpload", "imageTextAlternative", "imageStyle:full", "imageStyle:side", "mediaEmbed"]
        }
      },
    }
  },
  watch: {
    '$route.query': function() {
      console.log(this.$route.query);
      if(this.$route.query.tbcode) this.writePostVar.tbcode = this.$route.query.tbcode;
      if(this.$route.query.idx) this.writePostVar.idx = this.$route.query.idx;
      window.scrollTo(0,0);
    }
  },
  computed: {
    // PROFILE
    activeUserInfo() {
        return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    getPost(postId, password){
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/board/getPost', {'idx': postId, 'tbcode': this.writePostVar.tbcode, password })
        .then(function (response) {
          console.log(response.data);
          thisIns.writePostVar.fileNames = [];
          for(var i=0; i < response.data[1].length; i++){
            thisIns.writePostVar.fileNames.push(response.data[1][i].filename)
            thisIns.files.push({name: response.data[1][i].filename, size: 1})
            setTimeout(function(i) {
              console.log(i, thisIns.$refs['preview'+parseInt( i )])
              thisIns.$refs['preview'+parseInt( i )][0].src = thisIns.makeImageLink(response.data[1][i].filename, thisIns.writePostVar.tbcode);
              thisIns.$refs['preview'+parseInt( i )][0].style = "max-width: 100%";
            }.bind(null, i), 200);
          }
          thisIns.writePostVar.title = response.data[0][0].title;
          thisIns.writePostVar.contents = response.data[0][0].contents;
          if(response.data[0][0].notice == 0) { thisIns.writePostVar.postType = {value: 0, text: '일반'}; }
          else { thisIns.writePostVar.postType = {value: 1, text: '공지'}; }
          thisIns.writePostVar.writerName = response.data[0][0].writer;
          thisIns.writePostVar.writerIdx = response.data[0][0].writer_idx;
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    setPost(){
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      thisIns.writePostVar.postType = thisIns.writePostVar.postType.value;
      this.$http.post('/api/board/setPost', this.writePostVar)
        .then(function (response) {
          console.log(response);
          thisIns.$vs.loading.close();
          thisIns.$router.push({path: '/board', query: {tbcode: thisIns.writePostVar.tbcode}});
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    goToList(){
      this.$router.push({path: '/board', query: {tbcode: this.writePostVar.tbcode}});
    },
    makeImageLink(filename, tbcode){
      return "/api/board/viewImages?filename=" + filename + "&tbcode=" + tbcode;
    },
    makeUploadLink(){
      var linkText = "/api/board/uploadFile?tbcode=" + this.writePostVar.tbcode;
      return linkText;
    },
    removeFile(index){
      this.files.splice(index,1);
      this.writePostVar.fileNames.splice(index,1);
    },
    addFile(e){
      // console.log(e, e.target.files);
      for( let i = 0; i < e.target.files.length; i++ ){
        this.files.push( e.target.files[i] );
      }
      this.getImagePreviews()
      this.submitFiles(e.target.files);
    },
    // successUpload(e){
    //   // console.log(e, e.target.response);
    //   this.writePostVar.fileNames.push(e.target.response);
    //   this.$vs.notify({color:'success',title:'업로드 성공',text:'파일 업로드가 성공했습니다.'})
    // },
    // failUpload(e){
    //   // console.log(e, e.target.response);
    //   this.writePostVar.fileNames.push(e.target.response);
    //   // console.log(this.writePostVar.fileNames);
    //   alert('중복된 파일명입니다!\n기존에 업로드된 파일로 사용됩니다.')
    // },
    // deleteFile(e){
    //   // console.log(e, e.name);
    //   this.writePostVar.fileNames = this.writePostVar.fileNames.filter(el => !el.includes(e.name));
    //   // console.log(this.writePostVar.fileNames);
    // },
    /*
      Gets the image preview for the file.
    */
    getImagePreviews(){
      /*
        Iterate over all of the files and generate an image preview for each one.
      */
      for( let i = 0; i < this.files.length; i++ ){
        /*
          Ensure the file is an image file
        */
        if ( /\.(jpe?g|png|gif|bmp)$/i.test( this.files[i].name ) && this.files[i].size) {
          /*
            Create a new FileReader object
          */
          let reader = new FileReader();

          /*
            Add an event listener for when the file has been loaded
            to update the src on the file preview.
          */
          reader.addEventListener("load", function(){
            console.log(this.$refs['preview'+parseInt( i )]);
            this.$refs['preview'+parseInt( i )][0].src = reader.result;
            this.$refs['preview'+parseInt( i )][0].style = "max-width: 100%";
          }.bind(this), false);

          /*
            Read the data for the file in through the reader. When it has
            been loaded, we listen to the event propagated and set the image
            src to what was loaded from the reader.
          */
          reader.readAsDataURL( this.files[i] );
        }else{
          /*
            We do the next tick so the reference is bound and we can access it.
          */
          this.$nextTick(function(){
            this.$refs['preview'+parseInt( i )][0].src = '';
          });
        }
      }
    },
    /*
      Determines if the drag and drop functionality is in the
      window
    */
    determineDragAndDropCapable(){
      /*
        Create a test element to see if certain events
        are present that let us do drag and drop.
      */
      var div = document.createElement('div');

      /*
        Check to see if the `draggable` event is in the element
        or the `ondragstart` and `ondrop` events are in the element. If
        they are, then we have what we need for dragging and dropping files.

        We also check to see if the window has `FormData` and `FileReader` objects
        present so we can do our AJAX uploading
      */
      return ( ( 'draggable' in div )
              || ( 'ondragstart' in div && 'ondrop' in div ) )
              && 'FormData' in window
              && 'FileReader' in window;
    },

    /*
      Submits the files to the server
    */
    submitFiles(files){
      /*
        Initialize the form data
      */
      let formData = new FormData();
      let thisIns = this;

      /*
        Iteate over any file sent over appending the files
        to the form data.
      */
      for( var i = 0; i < files.length; i++ ){
        let file = files[i];

        formData.append('file', file);
      }
      /*
        Make the request to the POST /file-drag-drop-instant URL
      */
      axios.post( '/api/board/uploadFile',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          }.bind(this)
        }
      ).then(function(response){
        console.log('SUCCESS!!', response);
        thisIns.writePostVar.fileNames = thisIns.writePostVar.fileNames.concat(response.data);
      })
      .catch(function(response){
        console.log('FAILURE!!', response);
      });
    },
    showSidebar() {
      this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    // console.log(Array.from( this.editor.ui.componentFactory.names()))
    this.writePostVar.writerName = this.$store.state.AppActiveUser.name;
    this.writePostVar.writerIdx = this.$store.state.AppActiveUser.id;
    if(this.$route.query.tbcode) this.writePostVar.tbcode = this.$route.query.tbcode;
    if(this.$route.query.idx) {
      this.writePostVar.idx = this.$route.query.idx;
      
      if(this.writePostVar.tbcode == 103 && this.activeUserInfo.level < 8){
        alert('권한이 없습니다.')
        this.$router.go(-1)
      }
      this.postData = '';
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/board/getList', {tbcode: this.writePostVar.tbcode, startRow: 0, itemsPerPage: 1})
        .then(function (response) {
          // console.log(response);
          // thisIns.boardTitle = response.data[2].tbtitle;
          // thisIns.viewPassword = response.data[2].view_password;
          
          thisIns.getPost(thisIns.writePostVar.idx, response.data[2].password);
          thisIns.$vs.loading.close();          
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    }
  },
  mounted() {
    this.isMounted = true;
          /*
        Determine if drag and drop functionality is capable in the browser
      */
      this.dragAndDropCapable = this.determineDragAndDropCapable();

      /*
        If drag and drop capable, then we continue to bind events to our elements.
      */
      if( this.dragAndDropCapable ){
        /*
          Listen to all of the drag events and bind an event listener to each
          for the fileform.
        */
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
          /*
            For each event add an event listener that prevents the default action
            (opening the file in the browser) and stop the propagation of the event (so
            no other elements open the file in the browser)
          */
          this.$refs.fileform.addEventListener(evt, function(e){
            e.preventDefault();
            e.stopPropagation();
          }.bind(this), false);
        }.bind(this));

        /*
          Add an event listener for drop to the form
        */
        this.$refs.fileform.addEventListener('drop', function(e){
          /*
            Capture the files from the drop event and add them to our local files
            array.
          */
          for( let i = 0; i < e.dataTransfer.files.length; i++ ){
            this.files.push( e.dataTransfer.files[i] );
          }
          console.log(e.dataTransfer.files);
          this.getImagePreviews()
          this.submitFiles(e.dataTransfer.files);
        }.bind(this));
      }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/upload.scss";
</style>